import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const KeyFeaturesSection = () => {
  const [keyFeatures, setKeyFeatures] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState(null); // State for the selected feature

  // Fetch KeyFeatures from API for "Prime X" product only
  useEffect(() => {
    const fetchKeyFeatures = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/keyfeatures/all/`
        );
        if (response.ok) {
          const data = await response.json();
          const primeXProduct = data.find(
            (product) => product.product_name === "Prime X Casing-Capping"
          );
          if (primeXProduct) {
            setKeyFeatures(primeXProduct.features); // Set only "Prime X" features
          } else {
            console.error("Prime X product not found");
          }
        } else {
          console.error("Error fetching key features", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching key features", error);
      }
    };
    fetchKeyFeatures();
  }, []);

  const settings = {
    infinite: true,
    autoPlay: true,
    autoPlayInterval: 1000,
    animationDuration: 800,
    disableButtonsControls: true,
    disableDotsControls: true,
    responsive: {
      0: { items: 3 },
      480: { items: 3 },
      768: { items: 3 },
      1024: { items: 5 },
    },
  };

  return (
    <>
      {keyFeatures.length > 0 && (
        <div className="mt-5">
          <h2
            id="award"
            className="md:text-2xl text-sm font-bold text-center w-full  text-black"
          >
            {/* <span id="award" className="text-black">
              Prime-
            </span>
            <span id="award" className="text-[red]">
              X
            </span>{" "} */}
            Key Features
          </h2>

          {/* Display Selected Feature */}
          {selectedFeature && (
            <div className="relative p-4 my-2 bg-gray-100 rounded-lg shadow-lg flex flex-col items-center transition-opacity duration-300 ease-in-out">
              {/* Close Button */}
              <button
                onClick={() => setSelectedFeature(null)}
                className="absolute top-1 right-3 text-black text-4xl bg-[#ffffff35]      focus:outline-none"
              >
                &times;
              </button>

              <img
                src={selectedFeature.imgLink}
                alt={selectedFeature.title}
                className="w-auto h-20 object-cover rounded-t-md mb-2"
              />
              <h3 className="text-sm font-semibold text-gray-800 mb-2">
                {selectedFeature.title}
              </h3>
              <p className="text-gray-600 text-[12px]">
                {selectedFeature.description}
              </p>
            </div>
          )}

          <div className="w-full overflow-hidden mt-2 h-auto">
            <AliceCarousel {...settings}>
              {keyFeatures.map((feature) => (
                <div
                  key={feature._id}
                  className="p-1 bg-white rounded-lg flex  flex-col items-center justify-center cursor-pointer"
                  style={{ height: "100px", overflow: "hidden" }}
                  onClick={() => setSelectedFeature(feature)} // Set clicked feature as selected
                >
                  <img
                    src={feature.imgLink}
                    alt={feature.title}
                    className="w-auto h-20 object-cover rounded-t-md"
                  />
                  <h3 className=" text-[11px] font-semibold text-gray-800">
                    {feature.title}
                  </h3>
                </div>
              ))}
            </AliceCarousel>
          </div>
        </div>
      )}
    </>
  );
};

export default KeyFeaturesSection;
