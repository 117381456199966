import React, { useState, useEffect } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { motion, AnimatePresence } from "framer-motion";
import KeyFeaturesSection from "./KeyFeaturesSection";
import YouTubeVideoCarousel from "./YouTubeVideoCarousel";
import Certificate from "../Certificates/Certificate";

const PrimeX = () => {
  const [productData, setProductData] = useState({});
  const [sizes, setSizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentColor, setCurrentColor] = useState("white"); // Initial color set to "white"
  const [currentImages, setCurrentImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [expandedImageSrc, setExpandedImageSrc] = useState("");

  const fetchProductById = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/671604a1e0fe1202af9875b4`
      );
      setProductData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching product data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductById();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (productData?.products?.length > 0) {
      const newSizes = productData.products.map((productItem) => {
        const colors = Object.keys(productItem.colors).map((colorKey) => ({
          [colorKey]: productItem.colors[colorKey],
        }));
        return {
          title: productData.stressCategory,
          category: " " + productItem.title,
          description: productItem.description || "",
          ytLinks: productData.ytLinks,
          colors: Object.assign({}, ...colors),
        };
      });

      setSizes(newSizes);
      setSelectedSize(newSizes[0]);
      setCurrentImages(newSizes[0].colors["white"] || []); // Start with white images
    }
  }, [productData]);

  useEffect(() => {
    if (currentColor === "white" && selectedSize.colors?.white) {
      const totalImages = selectedSize.colors.white.length;

      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex + 1 < totalImages ? prevIndex + 1 : 0
        );
      }, 6000);

      return () => clearInterval(interval);
    }
  }, [currentColor, selectedSize]);

  const handleColorChange = (color) => {
    if (selectedSize.colors && selectedSize.colors[color]) {
      setCurrentImages(selectedSize.colors[color]);
      setCurrentColor(color); // Update color on selection
      setCurrentImageIndex(0);
    }
  };

  const openFullScreen = () => {
    setExpandedImageSrc(currentImages[currentImageIndex]);
    setShowFullScreen(true);
  };

  return (
    <div className="mx-auto px-5 py-5 max-w-6xl space-y-5">
      {/* Full-Screen Modal for Expanded Image */}
      <AnimatePresence>
        {showFullScreen && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="relative space-y-5">
              <button
                onClick={() => setShowFullScreen(false)}
                className="absolute top-5 right-3 text-black text-4xl bg-[#ffffff35]      focus:outline-none"
              >
                &times;
              </button>
              <img
                src={expandedImageSrc}
                alt="Full Screen Product"
                className="w-full h-auto max-w-screen-lg max-h-screen-lg object-contain"
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <nav className="flex mb-5" aria-label="Breadcrumb">
        <ol
          id="award"
          className="inline-flex text-[10px] items-center space-x-1 md:space-x-3 font-mono text-black "
        >
          <li>
            <a
              href="/"
              className="font-semibold text-black hover:text-gray-600 transition-colors duration-200"
            >
              Home
            </a>
          </li>
          <li className="font-light text-gray-500">/</li>
          <li className="font-semibold text-black hover:text-gray-600 transition-colors duration-200">
            Thunder
          </li>
          <li className="font-light text-gray-500">/</li>
          <li className="font-semibold text-black hover:text-gray-600 transition-colors duration-200">
            Pipes
          </li>
        </ol>
      </nav>

      <div className="flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5">
        <div className="md:w-1/2 space-y-5">
          <div className="flex justify-center mb-4">
            {loading ? (
              <Skeleton height={300} width={300} />
            ) : (
              <img
                src={currentImages[currentImageIndex] || ""}
                alt="Selected Product"
                className="w-full h-auto max-h-96 object-contain rounded-md cursor-pointer"
                onClick={openFullScreen}
              />
            )}
          </div>
          <div className="flex justify-center items-center mt-4 space-x-2">
            {loading
              ? [...Array(3)].map((_, index) => (
                  <Skeleton key={index} width={80} height={60} />
                ))
              : currentImages.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`Product Image ${index + 1}`}
                    className={`w-15 h-16 rounded-md cursor-pointer transition-transform transform ${
                      currentImageIndex === index
                        ? "border-2 border-customBlue scale-105"
                        : "border border-gray-300 hover:scale-105"
                    }`}
                    onClick={() => setCurrentImageIndex(index)}
                  />
                ))}
          </div>
        </div>

        <div className="md:w-1/2 md:pl-8  space-y-4">
          <h1 className="text-xl font-bold font-mono space-y-2 flex flex-col    mb-2 text-black">
            <div>
              <span id="award" className=" text-customBlue">
                Thunder
              </span>
            </div>
            <span id="award" className="text-xl  text-customBlue">
              Pvc Pipes
            </span>
          </h1>

          <div className="mt-4 space-y-4">
            <h2 className="text-lg font-bold font-mono text-black">
              Select Size:
            </h2>
            <ul
              className={`grid ${
                sizes.length === 1
                  ? "grid-cols-1"
                  : "grid-cols-2 md:grid-cols-3"
              } gap-2`}
            >
              {loading
                ? [...Array(3)].map((_, index) => (
                    <li key={index}>
                      <Skeleton width={100} height={40} />
                    </li>
                  ))
                : sizes.map((size) => (
                    <li key={size.title}>
                      <button
                        className={`w-full py-2 px-3 rounded-md border text-center transition-all ${
                          selectedSize.title === size.title
                            ? "bg-customBlue text-white"
                            : "bg-white text-black border-gray-300 hover:bg-gray-100"
                        }`}
                        onClick={() => {
                          setSelectedSize(size);
                          setCurrentImages(size.colors["white"] || []); // Initialize with white images
                          setCurrentColor("white"); // Set the color to white initially
                          setCurrentImageIndex(0);
                        }}
                      >
                        {size.title}
                        {size.category} MM
                      </button>
                    </li>
                  ))}
            </ul>
          </div>

          <div className="mt-4 space-y-4">
            <h2 className="text-lg font-bold font-mono text-black">
              Select Color:
            </h2>
            <ul
              className={`grid ${
                sizes.length === 1
                  ? "grid-cols-1"
                  : "grid-cols-2 md:grid-cols-3"
              } gap-2`}
            >
              {loading
                ? [...Array(3)].map((_, index) => (
                    <li key={index}>
                      <Skeleton width={100} height={40} />
                    </li>
                  ))
                : selectedSize.colors &&
                  Object.keys(selectedSize.colors).map((color) => (
                    <button
                      key={color}
                      className={`p-2 border rounded ${
                        currentColor === color
                          ? "bg-customBlue text-white"
                          : "bg-white text-black"
                      }`}
                      onClick={() => handleColorChange(color)}
                    >
                      {color.charAt(0).toUpperCase() + color.slice(1)}
                    </button>
                  ))}
            </ul>
          </div>

          <div className="mt-4 space-y-4">
            <h2
              id="award"
              className="text-sm font-bold justify-center items-center flex space-x-2 font-mono text-black"
            >
              <span className="text-sm">Products</span>
              <span className="text-sm">Description:</span>
            </h2>
            <p className="text-sm text-gray-700">
              {selectedSize.description || "No description available"}
            </p>
          </div>

          <div className="mt-4 text-center space-y-4">
            <h2
              id="award"
              className="text-sm font-bold justify-center items-center flex space-x-2 font-mono text-black"
            >
              <span id="award" className="text-customBlue border-r">
                Thunder
              </span>
              <span id="award" className="text-customBlue">
                Pipes
              </span>{" "}
              <span className="text-sm">Description:</span>
            </h2>
            <p className="text-sm text-black">
              {productData.description || "Loading..."}
            </p>
          </div>

          <Certificate />

          <KeyFeaturesSection />
          <YouTubeVideoCarousel
            ytLinks={
              selectedSize.ytLinks
                ? selectedSize.ytLinks.map((yt) => yt.link)
                : []
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PrimeX;
