import React, { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const Certificate = () => {
  const [certificates, setCertificates] = useState([]);

  const [selectedCertificate, setSelectedCertificate] = useState(null); // State for selected certificate

  // Fetch Certificates from API
  useEffect(() => {
    const fetchCertificates = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/singleCertificates/all`
        );
        if (response.ok) {
          const data = await response.json();
          setCertificates(data); // Set the fetched certificates
        } else {
          console.error("Error fetching certificates", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching certificates", error);
      }
    };
    fetchCertificates();
  }, []);

  const settings = {
    infinite: true,
    autoPlay: true,
    autoPlayInterval: 2000,
    animationDuration: 800,
    disableButtonsControls: true,
    disableDotsControls: true,
    responsive: {
      0: { items: 4 },
      480: { items: 3 },
      768: { items: 4 },
      1024: { items: 5 },
    },
  };

  return (
    <>
      {certificates.length > 0 && (
        <div className="mt-5">
          <h2
            id="award"
            className="md:text-2xl text-sm font-bold text-center w-full text-black"
          >
            Certificates
          </h2>

          {/* Display Selected Certificate */}
          {selectedCertificate && (
            <div className="relative p-4 my-2 bg-gray-100 rounded-lg shadow-lg flex flex-col items-center transition-opacity duration-300 ease-in-out">
              {/* Close Button */}
              <button
                onClick={() => setSelectedCertificate(null)}
                className="absolute top-1 right-3 text-black text-4xl bg-[#ffffff35] focus:outline-none"
              >
                &times;
              </button>

              <img
                src={selectedCertificate.img}
                alt={selectedCertificate.title}
                className="w-auto h-20 object-cover rounded-t-md mb-2"
              />

              <p className=" text-center font-bold text-xl w-full">
                {selectedCertificate.title}
              </p>
              <p className=" text-center text-[12px] w-full">
                {selectedCertificate.description}
              </p>
            </div>
          )}

          <div className="w-full overflow-hidden mt-2 h-auto">
            <AliceCarousel {...settings}>
              {certificates.map((certificate) => (
                <div
                  key={certificate._id}
                  className="p-1 bg-white rounded-lg flex flex-col items-center justify-center cursor-pointer"
                  style={{ height: "100px", overflow: "hidden" }}
                  onClick={() => setSelectedCertificate(certificate)} // Set clicked certificate as selected
                >
                  <img
                    src={certificate.img}
                    alt={certificate.title}
                    className="w-auto h-20 object-cover rounded-t-md"
                  />

                  <h3 className=" text-[11px] font-semibold text-gray-800">
                    {certificate.title}
                  </h3>
                </div>
              ))}
            </AliceCarousel>
          </div>
        </div>
      )}
    </>
  );
};

export default Certificate;
