import React, { useEffect, useState } from "react";
import axios from "axios";
import Carousel from "../Components/Carousel";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

const HomePage = () => {
  const featuredProducts = [
    {
      id: 1,
      name: "PVC Pipes",
      links: [
        {
          url: "/prime-x/pipes/lms",
          title: "LMS",
          theme: "Light Mechanical Stress",
          bgColor: "rgb(148, 27, 30)",
          textColor: "#FFFFFF",
        },
        {
          url: "/prime-x/pipes/mms",
          title: "MMS",
          theme: "Medium Mechanical Stress",
          bgColor: "rgb(43, 71, 119)",
          textColor: "#FFFFFF",
        },
        {
          url: "/prime-x/pipes/hms",
          title: "HMS",
          theme: "Heavy Mechanical Stress",
          bgColor: "rgb(56, 127, 116)",
          textColor: "#FFFFFF",
        },
        {
          url: "/prime-x/pipes/sms",
          title: "SMS",
          theme: "Super Mechanical Stress",
          bgColor: "rgb(224, 178, 39)",
          textColor: "#000000",
        },
      ],
    },
    {
      id: 2,
      name: "Casing -N- Capping",
      links: [
        {
          url: "/prime-x/casing-capping/20x12",
          title: "20x12",
          theme: "20mm OR 0.75 inch",
        },
        {
          url: "/prime-x/casing-capping/16x25",
          title: "16x25",
          theme: "25mm OR 1 inch",
        },
        {
          url: "/prime-x/casing-capping/32x16",
          title: "32x16",
          theme: "32mm OR 1.25 inch",
        },
      ],
    },
    {
      id: 3,
      name: "PVC Bend",
      links: [
        {
          url: "/prime-x/bends/hms",
          title: "HMS",
          theme: "Heavy Mechanical Stress",
          bgColor: "rgb(56, 127, 116)",
          textColor: "#FFFFFF",
        },
        {
          url: "/prime-x/bends/sms",
          title: "SMS",
          theme: "Super Mechanical Stress",
          bgColor: "rgb(224, 178, 39)",
          textColor: "#000000",
        },
      ],
    },
  ];

  const [videos, setVideos] = useState([]);
  const [screenType, setScreenType] = useState("Mobile");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const updateScreenType = () => {
      setScreenType(window.innerWidth > 768 ? "Desktop" : "Mobile");
    };

    updateScreenType();
    window.addEventListener("resize", updateScreenType);

    return () => {
      window.removeEventListener("resize", updateScreenType);
    };
  }, []);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/videos/all/`
        );
        setVideos(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching videos:", error);
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const productsVideo = videos.find(
    (video) => video.title === "Prime X" && video.description === screenType
  );

  return (
    <div className="min-h-screen md:w-[85%] mx-auto bg-white font-roboto">
      {screenType === "Mobile" && (
        <div>
          {loading ? (
            <Skeleton height={500} width="100%" />
          ) : (
            productsVideo && <Carousel videoUrl={productsVideo.videoUrl} />
          )}
        </div>
      )}

      <main className="container mx-auto px-4">
        <section>
          <h3 className="text-xl my-5 font-bold text-gray-800 text-center">
            Featured Products
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {featuredProducts.map((product) => (
              <div key={product.id} className="flex flex-col">
                <div className="relative flex flex-col items-center justify-center rounded-lg shadow-xl transition duration-300 transform bg-customBlue text-white overflow-hidden cursor-pointer h-auto w-full">
                  <div className="md:p-6 p-4 text-center">
                    <h4 className="md:text-4xl text-xl uppercase font-semibold">
                      {product.name}
                    </h4>
                  </div>
                </div>

                <div className="my-5 text-left uppercase bg-white text-black border border-gray-300 p-4 rounded-lg shadow-md w-full">
                  {product.links.map((link, index) => (
                    <Link
                      key={index}
                      to={link.url}
                      className="mb-4 block"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div
                        className="font-bold hover:text-blue-500 transition duration-200"
                        style={{ color: link.bgColor }}
                      >
                        {link.title}
                      </div>
                      <div className="text-sm italic text-gray-600 hover:text-blue-400">
                        {link.theme}
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};

export default HomePage;
