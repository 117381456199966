import React, { useRef, useState } from "react";
import { FaVolumeMute, FaVolumeUp } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";

const Carousel = ({ videoUrl }) => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true); // State to track if the video is muted
  const [isVideoLoaded, setIsVideoLoaded] = useState(false); // State to track if the video is loaded

  // Function to toggle video sound
  const toggleSound = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className="relative">
      {!isVideoLoaded && <Skeleton height={500} width="100%" />}{" "}
      {/* Skeleton Loader */}
      <video
        ref={videoRef}
        className="md:object-center w-full h-auto"
        loop
        autoPlay
        muted // Start muted
        src={videoUrl}
        onLoadedData={() => setIsVideoLoaded(true)} // Update state when video is loaded
        style={{ display: isVideoLoaded ? "block" : "none" }} // Hide video until loaded
      />
      {/* Sound Control Button */}
      {isVideoLoaded && (
        <div className="absolute bottom-4 right-2">
          <button
            onClick={toggleSound}
            className="p-2 bg-transparent rounded-full"
          >
            {isMuted ? (
              <FaVolumeMute size={24} className="text-black" />
            ) : (
              <FaVolumeUp size={24} className="text-black" />
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default Carousel;
